import React from "react"
// import { graphql } from "gatsby"
// import Img from "gatsby-image"

export default function Tags({ data }) {
  return (
    <div>
      <div>Tags</div>
    </div>
  )
}
